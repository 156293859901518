import Layout from "components/includes/Layout";
import { useContext } from "react";
import { connect } from "react-redux";

import { logout } from "src/redux/action/auth";
import { I18nContext } from "components/I18N";
import Link from "next/link";

const Page = ({}) => {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);

  return (
    <Layout header headerFullwidth>
      <div className="relative bg-[url(/images/404/background.png)] bg-center bg-cover bg-no-repeat w-screen h-[60vh] md:h-screen">
        <div className="absolute top-[50%] left-[50%] text-center text-white flex flex-col items-center w-full translate-x-[-50%] translate-y-[-50%] px-[24px]">
          <p className="text-[4rem] md:text-[6rem] font-[CentraNo2] font-extrabold">
            404
          </p>
          <p className="text-[1.2rem] md:text-[1.6rem] font-extrabold">
            {I18n.get("sorryMsg404")}
          </p>

          <Link href="/">
            <button className="big light">
              <p className="title text-[#060606] font-bold">
                {I18n.get("backToHomePage")}
              </p>
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Page);
